.open {
    right: 1em;
    transition: right .7s .3s ease;
  }
  
  .close {
    right: -100%;
    transition: right .7s .3s ease;
  }
  
  .toast {
    position: fixed;
    top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 3em;
    width: calc(100% - 2em);
    border-radius: 5px;
    z-index: 1000;
    font-size: 18px;
    text-align: center;
    line-height: 1em;
    /* background: toast?.error ? 'rgba(255,90,90, 1)' : 'rgba(40,145,40, 1)',
    borderColor: toast?.error ? 'rgba(255,40,40, 1)' : 'rgba(20,145,20, 1)', */
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
  }